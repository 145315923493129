import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { PieChart } from "@mui/x-charts/PieChart";
import { DataGrid } from "@mui/x-data-grid";
import { BarChart } from "@mui/x-charts/BarChart";
import { useGenderData } from "./userGender";
import { useStatusPie } from "./userStatusPie";
import { useMunicipalityData } from "./userMunicipality";
import { useDepartmentData } from "./userDepartment";
import { useProgramData } from "./userProgram";
import { valueFormatter } from "./valueFormatter";
import { Card, CardContent, Typography } from "@mui/material";
import axios from "axios";

const Integration = () => {
  const genderData = useGenderData();
  const statusPieData = useStatusPie();
  const municipalityData = useMunicipalityData();
  const departmentData = useDepartmentData();
  const programData = useProgramData();

  const [dates, setDates] = useState([]);
  const [applicantCounts, setApplicantCounts] = useState([]);

  useEffect(() => {
    const fetchAcceptedApplicantsData = async () => {
      try {
        const response = await axios.get(
          "https://bsusms.online/api/applicants/accepted-per-date"
        );
        const data = response.data;
  
        // Format dates as Month Name and Day (e.g., Nov 27)
        const dateLabels = data.map((item) => {
          const date = new Date(item.date);
          const options = { month: 'short', day: 'numeric' }; // Format to "Month Day"
          return date.toLocaleDateString('en-US', options);
        });
  
        const countData = data.map((item) => item.applicantCount);
  
        setDates(dateLabels);
        setApplicantCounts(countData);
      } catch (error) {
        console.error("Error fetching accepted applicant data:", error);
      }
    };
  
    fetchAcceptedApplicantsData();
  }, []);

  // Prepare columns for DataGrid
  const columnsDepartment = [
    { field: "label", headerName: "Department", width: 200 },
    { field: "value", headerName: "Count", width: 150 },
  ];

  // Map the department data for Data Grid
  const rowsDepartment = departmentData.map((item, index) => ({
    id: index, // Use index as a unique key
    label: item.label,
    value: item.value,
  }));

  // Prepare columns for DataGrid
  const columnsProgram = [
    { field: "label", headerName: "Program", width: 200 },
    { field: "value", headerName: "Count", width: 150 },
  ];

  // Map the department data for Data Grid
  const rowsProgram = programData.map((item, index) => ({
    id: index, // Use index as a unique key
    label: item.label,
    value: item.value,
  }));

  // Define columns for the Data Grid
  const columns = [
    { field: "label", headerName: "Municipality", width: 200 },
    { field: "value", headerName: "Count", width: 150 },
  ];

  // Map the municipality data for Data Grid
  const rows = municipalityData.map((item, index) => ({
    id: index, // Use index as a unique key
    label: item.label,
    value: item.value,
  }));

  return (
    <Box
      sx={{
        minWidth: 275,
        marginBottom: 2,
        paddingLeft: "20px",
        paddingTop: "20px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // Two columns layout
        gap: "20px",
      }}
    >
      {/* First Row: Two Pie Charts */}
      <Card sx={{ width: "100%", height: 300, gridColumn: "span 1" }}>
        <CardContent>
          <Typography variant="h6" component="h2">
            Scholars Gender
          </Typography>
          <PieChart
            series={[
              {
                data: genderData,
                highlightScope: { fade: "global", highlight: "item" },
                innerRadius: 50,
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                valueFormatter,
              },
            ]}
            height={200}
          />
        </CardContent>
      </Card>

      <Card sx={{ width: "100%", height: 300, gridColumn: "span 1" }}>
        <CardContent>
          <Typography variant="h6" component="h2">
            Acceptance Rate
          </Typography>
          <PieChart
            series={[
              {
                data: statusPieData,
                highlightScope: { fade: "global", highlight: "item" },
                innerRadius: 50,
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                valueFormatter,
              },
            ]}
            height={200}
          />
          {/* Displaying only the percentage for REGISTERED status */}
          {statusPieData.map(
            (item) =>
              item.label === "Accepted Applicants" && (
                <Typography key={item.label}>
                  Acceptance Rate: ({item.percentage}%)
                </Typography>
              )
          )}
        </CardContent>
      </Card>

      {/* Second Row: Accepted Applicants Bar Chart */}
      <Card sx={{ width: "100%", height: 450, gridColumn: "span 2" }}>
        <CardContent>
          <Typography variant="h6" component="h2">
            Accepted Applicants Per Date
          </Typography>
          <BarChart
            xAxis={[{ scaleType: "band", data: dates, label: "Date" }]} // Use 'band' scale type
            series={[{ data: applicantCounts, label: "Accepted Applicants" }]}
            width={600}
            height={400}
            yAxis={[
              {
                tickFormatter: (value) => Math.floor(value), // Format to whole numbers
              },
            ]}
          />
        </CardContent>
      </Card>

      {/* Third Row: Three Data Grids */}
      <Card sx={{ width: "100%", height: 450, gridColumn: "span 1" }}>
        <CardContent>
          <Typography variant="h6" component="h2">
            Municipality Count
          </Typography>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns.map((column) => ({
                ...column,
                flex: 1,
                align: "center",
                headerAlign: "center",
              }))}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
              disableSelectionOnClick
              sx={{
                display: "flex",
                justifyContent: "center",
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                },
              }}
            />
          </div>
        </CardContent>
      </Card>

      <Card sx={{ width: "100%", height: 450, gridColumn: "span 1" }}>
        <CardContent>
          <Typography variant="h6" component="h2">
            Department Count
          </Typography>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={rowsDepartment}
              columns={columnsDepartment.map((column) => ({
                ...column,
                flex: 1,
                align: "center",
                headerAlign: "center",
              }))}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
              disableSelectionOnClick
              sx={{
                display: "flex",
                justifyContent: "center",
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                },
              }}
            />
          </div>
        </CardContent>
      </Card>

      <Card sx={{ width: "100%", height: 450, gridColumn: "span 1" }}>
        <CardContent>
          <Typography variant="h6" component="h2">
            Program Count
          </Typography>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              rows={rowsProgram}
              columns={columnsProgram.map((column) => ({
                ...column,
                flex: 1,
                align: "center",
                headerAlign: "center",
              }))}
              pageSize={5}
              rowsPerPageOptions={[5]}
              autoHeight
              disableSelectionOnClick
              sx={{
                display: "flex",
                justifyContent: "center",
                "& .MuiDataGrid-cell": {
                  textAlign: "center",
                },
              }}
            />
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Integration;
