import * as React from "react";
import PropTypes from "prop-types";
import { Button, Box, Menu, MenuItem, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CampaignIcon from "@mui/icons-material/Campaign";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";
import LayersIcon from "@mui/icons-material/Layers";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import Dashboard from "../components/admincomponents/dashboard";
import Content from "../components/admincomponents/content";
import Documents from "../components/admincomponents/admindocuments";
import Try from "../components/admincomponents/try";
import Scholarships from "../components/admincomponents/scholarships";
import Integ from "../components/admincomponents/intergration";
import Requirements from "../components/admincomponents/requirements";
import Demotable from "../components/admincomponents/demotable";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SchoolIcon from "@mui/icons-material/School";
import Scholars from "../components/admincomponents/scholars";
import { ArrowDropDown } from '@mui/icons-material';

const NAVIGATION = [
  {
    kind: "header",
    title: "Analytics",
  },
  {
    segment: "integrations",
    title: "Dashboard",
    icon: <LayersIcon />,
  },
  {
    kind: "header",
    title: "Main items",
  },
  {
    segment: "scholars",
    title: "Scholars",
    icon: <DashboardIcon />,
  },
  {
    segment: "applicant",
    title: "Applicant",
    icon: <AssignmentIndIcon />,
  },
  {
    segment: "scholarship",
    title: "Scholarships",
    icon: <SchoolIcon />,
  },
  {
    segment: "content",
    title: "Content",
    icon: <CampaignIcon />,
  },
  {
    kind: "divider",
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DemoPageContent({ pathname }) {
  switch (pathname) {
    case "/scholars":
      return <Scholars />;
    case "/content":
      return <Dashboard />;
    case "/documentss":
      return <Documents />;
    case "/scholarship":
      return <Try />;
    case "/scholarships":
      return <Scholarships />;
    case "/integrations":
      return <Integ />;
    case "/applicant":
      return <Requirements />;
    case "/demotable":
      return <Demotable />;
    default:
      return (
        <Box
          sx={{
            py: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography>Page Not Found</Typography>
        </Box>
      );
  }
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardLayoutBasic(props) {
  const { window } = props;
  const [pathname, setPathname] = React.useState("/dashboard");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const router = React.useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(path),
    };
  }, [pathname]);

  const demoWindow = window !== undefined ? window() : undefined;

  // Logout function
  const navigate = useNavigate();
  function logout() {
    // Clear sessionStorage or localStorage
    localStorage.removeItem("adminToken"); // or localStorage if you prefer

    // Redirect to login page
    navigate("/admin-bsu"); // Adjust path if needed
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppProvider
      navigation={NAVIGATION}
      router={router}
      theme={demoTheme}
      window={demoWindow}
      branding={{
        logo: (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src="/images/bsu_logo.png" alt="Your Logo" height="40" />
          </Box>
        ),
        title: (
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Typography variant="h6" sx={{ color: "red", fontWeight: "bold" }}>
              BATANGAS STATE UNIVERSITY SMS
            </Typography>
          </Box>
        ),
      }}
    >
      <DashboardLayout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            p: 2,
          }}
        >
          {/* Other content can be here */}
        </Box>
        <DemoPageContent pathname={pathname} />
      </DashboardLayout>
      
      {/* Admin Menu positioned on the right side of the screen */}
      <Box
        sx={{
          position: "fixed",
          top: "20px", // Adjust this to place it vertically as desired
          right: "70px", // Adjust this to move it slightly to the left
          zIndex: 9999, // Ensure it's on top of other elements
        }}
      >
        <Button
          onClick={handleClick}
          color="primary"
          variant="text"
          sx={{
            color: "red",
            fontWeight: "bold",
            textTransform: "none", // To keep the text uncapitalized like the title
            display: "flex",
            alignItems: "center", // Align text and icon
          }}
        >
          Admin Menu
          <ArrowDropDown sx={{ marginLeft: 1 }} /> {/* Adding the dropdown icon */}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ "aria-labelledby": "basic-button" }}
        >
          <MenuItem onClick={logout}>Logout</MenuItem>
          {/* You can add more items to the dropdown here */}
        </Menu>
      </Box>
    </AppProvider>
  );
}

DashboardLayoutBasic.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayoutBasic;
